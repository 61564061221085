.popup-list {
  .popup-box {
    max-width: 450px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3), 0px 5px 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 1000;

    &[data-index="1"] {
      top: 50%;
      left: 50%;
      transform: translate(-120%, -60%);
    }

    &[data-index="2"] {
      max-width: 500px;
      top: 50%;
      left: 50%;
      transform: translate(16%, -48%);
    }

    &[data-index="3"] {
      top: 50%;
      left: 50%;
      transform: translate(20%, -58.5%);

      .link-btn {
        cursor: pointer;
        position: absolute;
        top: 83.6%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 26%;
        height: 5%;
        border-radius: 5px;
        display: block;
      }
    }

    .content {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }
    }

    .bottom-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba($color: #fff, $alpha: 0.5);
      padding: 0 6px;

      .close-btn {
        > i {
          font-size: 0.8rem;
          color: #fff;
        }
        > span {
          font-size: 0.9rem;
          cursor: pointer;
        }
      }

      .checkbox {
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .popup-list {
    .popup-box {
      max-width: 360px;
      width: 80vw;
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3), 0px 5px 10px rgba(0, 0, 0, 0.2);
      position: fixed;
      z-index: 1000;

      &[data-index="1"] {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &[data-index="2"] {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &[data-index="3"] {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .content {
        img {
          width: 100%;
          height: auto;
        }
      }

      .bottom-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba($color: #fff, $alpha: 0.5);
        padding: 0 6px;

        .close-btn {
          > i {
            font-size: 1.2rem;
            color: #fff;
          }
          > span {
            font-size: 1.2rem;
            cursor: pointer;
          }
        }

        .checkbox {
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
