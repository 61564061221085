@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 100;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoT.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 200;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoUL.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 300;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoL.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 400;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoR.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoM.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 600;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoSB.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 700;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoB.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 800;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoEB.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 900;
  src: url("../assets/fonts/AppleSDGothicNeo/AppleSDGothicNeoH.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-display: swap;
  src: local("Pretendard Black"), url("../assets/fonts/Pretendard/Pretendard-Black.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Black.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard ExtraBold"), url("../assets/fonts/Pretendard/Pretendard-ExtraBold.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"), url("../assets/fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Bold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"), url("../assets/fonts/Pretendard/Pretendard-SemiBold.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"), url("../assets/fonts/Pretendard/Pretendard-Medium.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"), url("../assets/fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-display: swap;
  src: local("Pretendard Light"), url("../assets/fonts/Pretendard/Pretendard-Light.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-display: swap;
  src: local("Pretendard ExtraLight"), url("../assets/fonts/Pretendard/Pretendard-ExtraLight.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-display: swap;
  src: local("Pretendard Thin"), url("../assets/fonts/Pretendard/Pretendard-Thin.woff2") format("woff2"), url("../assets/fonts/Pretendard/Pretendard-Thin.woff") format("woff");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 100;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Thin.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 200;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 300;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Light.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 400;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Regular.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 500;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Medium.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 600;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 700;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Bold.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 800;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 900;
  src: url("../assets/fonts/Noto_Sans_KR/static/NotoSansKR-Black.ttf") format("truetype");
}

body {
  font-family: "AppleSDGothicNeo", "NotoSansKR", sans-serif;
  letter-spacing: -0.3px;

  * {
    font-family: "AppleSDGothicNeo", "NotoSansKR", sans-serif;
    letter-spacing: -0.3px;
  }
}
