#footer {
  width: 100%;
}

.footer-inner {
  max-width: 1920px;
  margin: 0 auto;
  padding: 4rem;
  margin: auto;
  position: relative;

  .footer-left {
    display: flex;
    background-color: #282e42;
    padding: 3rem;

    .footer-logo {
      width: 40%;
      display: flex;
      align-items: center;

      > img {
        max-width: 100%;
        max-height: 120px;
      }
    }

    .footer-info {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .footer-link {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;

        > li {
          margin-right: 1rem;

          > a {
            color: #fff;
            font-size: 1rem;
          }
        }
      }
      .footer-info-item {
        .title {
          color: #fff;
          font-size: 1rem;
        }

        .sub {
          color: #fff;
          font-size: 0.9rem;
        }
      }
      .footer-copyright {
        > span {
          font-size: 0.8rem;
          color: #fff;
        }
      }
    }
  }

  .footer-right {
    padding: 2rem 0 2rem 2rem;

    .copyright {
      > div:nth-of-type(1) {
        color: #2478d7;
        font-size: 1.8rem;
      }

      > div:nth-of-type(2) {
        color: #fff;
        font-size: 2rem;
      }
    }

    .pdf-container {
      margin-top: 2rem;

      > button {
        background-color: #282f42;
        padding: 0.8rem 1.2rem;
        border: none;
        border-radius: 5px;

        > a {
          color: #fff;
          display: flex;
          align-items: center;

          > img {
            width: 20px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .footer-navigation {
    position: absolute;
    right: 100px;
    bottom: 50px;

    > button {
      font-size: 0.8rem;
      color: #2478d7;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      gap: 1rem;
      transform: rotate(90deg);
      transform-origin: right;
      cursor: pointer;

      &::before {
        content: "";
        width: 70px;
        height: 1px;
        background: #2478d7;
        display: block;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-inner {
    padding: 0;

    .footer-left {
      .footer-logo {
        width: 30%;
        padding-right: 20px;

        > img {
          max-height: 90px;
          max-width: 100%;
        }
      }

      .footer-info {
        width: 70%;
        
        .footer-link {
          > li {
            > a {
              font-size: 1.2rem;
            }
          }
        }

        .footer-info-item {
          margin: 1rem 0;

          .title {
            font-size: 1.1rem;
          }

          .sub {
            font-size: 1rem;
          }
        }
      }
    }

    .footer-navigation {
      bottom: 20px;
      right: 15px;
    }
  }
}
