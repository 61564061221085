html {
  font-size: 16px;

  body {
    background-color: #000;
    margin: 0;

    * {
        box-sizing: border-box;
        word-break: keep-all;
    }

    a {
      text-decoration: none;
    }

    button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    em {
      font-style: normal;
      display: inline-block;
    }

    &.menuOn {
      overflow-y: hidden !important;
    }
  }
}

@media (max-width: 768px) {
  html {
    font-size: 10px;
  }
}