.main-inner {
  max-width: 1920px;
  margin: 0 auto;

  &.layout-inner {
    padding-top: 13rem;
  }

  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .title {
      position: relative;

      > span {
        font-size: 3rem;
        color: #fff;
        font-weight: 700;
      }

      &::after {
        content: "";
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: rgba($color: #fac001, $alpha: 0.6);
        display: block;
        position: absolute;
        right: -1rem;
        top: -0.5rem;
        z-index: -1;
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.5s;
        transition-delay: 0.6s;
        overflow: hidden;
      }

      &.on {
        &::after {
          opacity: 1;
          transform: translateY(0.5rem);
        }
      }
    }

    .bread-crumbs {
      display: flex;
      gap: 1rem;

      > li {
        i {
          font-size: 0.8rem;
          color: #999999;
        }

        span {
          font-size: 1rem;
          color: #999999;
        }

        &:last-of-type {
          span {
            color: #fff;
          }
        }
      }
    }
  }

  .contents {
    padding-bottom: 10rem;
    overflow-x: hidden;
    
    > section {
    }
  }
}

@media (max-width: 768px) {
  .main-inner {
    .page-title {
      .title {
        > span {
          font-size: 2.8rem;
        }
      }
      .bread-crumbs {
        > li {
          i {
            font-size: 1rem;
          }

          span {
            font-size: 1.2rem;
          }
        }
      }
    }

    .contents {
      padding-bottom: 100px;
    }
  }
}
