html {
  font-size: 16px;
}

html body {
  background-color: #000;
  margin: 0;
}

html body * {
  box-sizing: border-box;
  word-break: keep-all;
}

html body a {
  text-decoration: none;
}

html body button {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  padding: 0;
}

html body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html body em {
  font-style: normal;
  display: inline-block;
}

html body.menuOn {
  overflow-y: hidden !important;
}

@media (max-width: 768px) {
  html {
    font-size: 10px;
  }
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 100;
  src: url("AppleSDGothicNeoT.5b8a63b5.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 200;
  src: url("AppleSDGothicNeoUL.24a417b9.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 300;
  src: url("AppleSDGothicNeoL.f4b35e4f.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 400;
  src: url("AppleSDGothicNeoR.aaabd6e8.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  src: url("AppleSDGothicNeoM.42b01fc5.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 600;
  src: url("AppleSDGothicNeoSB.3efd41a4.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 700;
  src: url("AppleSDGothicNeoB.324d0911.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 800;
  src: url("AppleSDGothicNeoEB.334b6776.ttf") format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 900;
  src: url("AppleSDGothicNeoH.b798ff94.ttf") format("truetype");
}

@font-face {
  font-family: Pretendard;
  font-weight: 900;
  font-display: swap;
  src: local(Pretendard Black), url("Pretendard-Black.baa191d3.woff2") format("woff2"), url("Pretendard-Black.2ea8e4c6.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 800;
  font-display: swap;
  src: local(Pretendard ExtraBold), url("Pretendard-ExtraBold.947d535b.woff2") format("woff2"), url("Pretendard-ExtraBold.78682630.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 700;
  font-display: swap;
  src: local(Pretendard Bold), url("Pretendard-Bold.40de1c3d.woff2") format("woff2"), url("Pretendard-Bold.6c687d57.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 600;
  font-display: swap;
  src: local(Pretendard SemiBold), url("Pretendard-SemiBold.94a0a337.woff2") format("woff2"), url("Pretendard-SemiBold.1ca6caea.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 500;
  font-display: swap;
  src: local(Pretendard Medium), url("Pretendard-Medium.3948fb93.woff2") format("woff2"), url("Pretendard-Medium.991261ca.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 400;
  font-display: swap;
  src: local(Pretendard Regular), url("Pretendard-Regular.758d4bce.woff2") format("woff2"), url("Pretendard-Regular.946a4476.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 300;
  font-display: swap;
  src: local(Pretendard Light), url("Pretendard-Light.d9482a0e.woff2") format("woff2"), url("Pretendard-Light.db4c9083.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 200;
  font-display: swap;
  src: local(Pretendard ExtraLight), url("Pretendard-ExtraLight.89f4b51a.woff2") format("woff2"), url("Pretendard-ExtraLight.274de88a.woff") format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 100;
  font-display: swap;
  src: local(Pretendard Thin), url("Pretendard-Thin.b7f0139f.woff2") format("woff2"), url("Pretendard-Thin.75810b0b.woff") format("woff");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 100;
  src: url("NotoSansKR-Thin.9dc5dcf0.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 200;
  src: url("NotoSansKR-ExtraLight.536efcf8.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 300;
  src: url("NotoSansKR-Light.e443dd54.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 400;
  src: url("NotoSansKR-Regular.4a01ab58.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 500;
  src: url("NotoSansKR-Medium.2768b995.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 600;
  src: url("NotoSansKR-SemiBold.08b58ecb.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 700;
  src: url("NotoSansKR-Bold.d51153c1.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 800;
  src: url("NotoSansKR-ExtraBold.596725df.ttf") format("truetype");
}

@font-face {
  font-family: NotoSansKR;
  font-weight: 900;
  src: url("NotoSansKR-Black.a96ff24c.ttf") format("truetype");
}

body, body * {
  letter-spacing: -.3px;
  font-family: AppleSDGothicNeo, NotoSansKR, sans-serif;
}

.main-inner {
  max-width: 1920px;
  margin: 0 auto;
}

.main-inner.layout-inner {
  padding-top: 13rem;
}

.main-inner .page-title {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.main-inner .page-title .title {
  position: relative;
}

.main-inner .page-title .title > span {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
}

.main-inner .page-title .title:after {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  z-index: -1;
  opacity: 0;
  background-color: #fac00199;
  border-radius: 50%;
  transition: all .5s .6s;
  display: block;
  position: absolute;
  top: -.5rem;
  right: -1rem;
  overflow: hidden;
  transform: translateY(100%);
}

.main-inner .page-title .title.on:after {
  opacity: 1;
  transform: translateY(.5rem);
}

.main-inner .page-title .bread-crumbs {
  gap: 1rem;
  display: flex;
}

.main-inner .page-title .bread-crumbs > li i {
  color: #999;
  font-size: .8rem;
}

.main-inner .page-title .bread-crumbs > li span {
  color: #999;
  font-size: 1rem;
}

.main-inner .page-title .bread-crumbs > li:last-of-type span {
  color: #fff;
}

.main-inner .contents {
  padding-bottom: 10rem;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .main-inner .page-title .title > span {
    font-size: 2.8rem;
  }

  .main-inner .page-title .bread-crumbs > li i {
    font-size: 1rem;
  }

  .main-inner .page-title .bread-crumbs > li span {
    font-size: 1.2rem;
  }

  .main-inner .contents {
    padding-bottom: 100px;
  }
}

#header {
  width: 100%;
}

.header-inner {
  max-width: 1920px;
  width: 100%;
  height: 100px;
  z-index: 1000;
  transition: all .5s;
  position: fixed;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.header-inner.menuOn {
  box-shadow: 0 0 20px #0000001a;
}

.header-inner.menuOn .header-contents .logo .white-logo {
  display: none;
}

.header-inner.menuOn .header-contents .logo .color-logo {
  display: block;
}

.header-inner.menuOn .header-contents .nav {
  opacity: 0;
  z-index: -1;
}

.header-inner.menuOn .header-contents .menu-btn > button > span {
  background-color: #2c2c2c;
}

.header-inner.menuOn .header-contents .menu-btn > button > span:nth-of-type(2) {
  transform: translateY(8px);
}

.header-inner.menuOn .header-contents .menu-btn > button > span:nth-of-type(4) {
  transform: translateX(8px);
}

.header-inner.menuOn .header-contents .menu-btn > button > span:nth-of-type(6) {
  transform: translateX(-8px);
}

.header-inner.menuOn .header-contents .menu-btn > button > span:nth-of-type(8) {
  transform: translateY(-8px);
}

.header-inner.on {
  height: 400px;
  background-color: #fff;
}

.header-inner.on:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  display: block;
  position: absolute;
  top: 100px;
  left: 0;
}

.header-inner.on .header-contents .logo .white-logo {
  display: none;
}

.header-inner.on .header-contents .logo .color-logo {
  display: block;
}

.header-inner.on .header-contents .nav .depth1 > li > a {
  color: #222;
}

.header-inner.on .header-contents .nav .depth1 > li .depth2 {
  opacity: 1;
}

.header-inner.on .header-contents .menu-btn > button > span {
  background-color: #2c2c2c;
}

.header-inner.fix {
  background-color: #fff;
}

.header-inner.fix:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  display: block;
  position: absolute;
  top: 100px;
  left: 0;
}

.header-inner.fix .header-contents .logo .white-logo {
  display: none;
}

.header-inner.fix .header-contents .logo .color-logo {
  display: block;
}

.header-inner.fix .header-contents .nav .depth1 > li > a {
  color: #222;
}

.header-inner.fix .header-contents .nav .depth1 > li .depth2 {
  opacity: 1;
}

.header-inner.fix .header-contents .menu-btn > button > span {
  background-color: #2c2c2c;
}

.header-inner .header-contents {
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  display: flex;
}

.header-inner .header-contents .logo > a > img {
  max-height: 50px;
  width: auto;
}

.header-inner .header-contents .logo .white-logo {
  display: block;
}

.header-inner .header-contents .logo .color-logo {
  display: none;
}

.header-inner .header-contents .nav .depth1 {
  justify-content: center;
  display: flex;
}

.header-inner .header-contents .nav .depth1 > li {
  position: relative;
}

.header-inner .header-contents .nav .depth1 > li > a {
  color: #fff;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  font-size: 1rem;
  display: flex;
}

.header-inner .header-contents .nav .depth1 > li .depth2 {
  opacity: 0;
  width: 100%;
  text-align: center;
  padding: 30px 0 0;
  position: absolute;
}

.header-inner .header-contents .nav .depth1 > li .depth2 > li {
  margin-bottom: 2rem;
}

.header-inner .header-contents .nav .depth1 > li .depth2 > li > a {
  color: #666;
  font-size: 1rem;
}

.header-inner .header-contents .nav .depth1 > li:before {
  content: "";
  width: 100%;
  z-index: -1;
  height: 400px;
  opacity: 0;
  background: #f9f9f9;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.header-inner .header-contents .nav .depth1 > li:hover > a {
  color: #2478d7;
}

.header-inner .header-contents .nav .depth1 > li:hover:before {
  opacity: 1;
}

.header-inner .header-contents .menu-btn {
  transition: all .5s;
}

.header-inner .header-contents .menu-btn > button {
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  display: grid;
}

.header-inner .header-contents .menu-btn > button > span {
  width: 3px;
  height: 3px;
  background-color: #fff;
  transition: all .5s;
  display: block;
}

.header-inner .header-contents .menu-btn:hover {
  transform: rotate(90deg);
}

.header-menu {
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: -1;
  background-color: #fff;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.header-menu.on {
  opacity: 1;
  z-index: 999;
}

.header-menu .nav {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-menu .nav .depth1 {
  max-width: 60%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.header-menu .nav .depth1 > li {
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  padding: 2rem 0;
}

.header-menu .nav .depth1 > li:hover > a {
  color: #2478d7;
  padding-left: 3rem;
}

.header-menu .nav .depth1 > li:hover > a:before {
  opacity: 1;
  left: 0;
}

.header-menu .nav .depth1 > li.on > a {
  color: #2478d7;
  padding-left: 3rem;
}

.header-menu .nav .depth1 > li.on > a:before {
  opacity: 1;
  left: 0;
}

.header-menu .nav .depth1 > li > a {
  width: 15rem;
  color: #222;
  font-size: 2rem;
  font-weight: 700;
  transition: all .5s;
  display: block;
  position: relative;
}

.header-menu .nav .depth1 > li > a:before {
  content: "";
  width: 15px;
  height: 15px;
  transform-origin: top;
  opacity: 0;
  background-color: #2478d7;
  border-radius: 4px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: rotate(45deg)translateY(-50%);
}

.header-menu .nav .depth1 > li .depth2 > li {
  margin-right: 2rem;
}

.header-menu .nav .depth1 > li .depth2 > li > a {
  color: #666;
  font-size: 1.1rem;
}

.header-menu .nav .depth1 > li .depth2 > li > a:hover {
  color: #2478d7;
}

@media (max-width: 768px) {
  .header-inner {
    height: 80px;
  }

  .header-inner .header-contents {
    height: 100%;
    align-items: center;
  }

  .header-inner .header-contents .logo > a > img {
    max-height: 40px;
  }

  .header-menu .nav .depth1 > li > a {
    font-size: 2.5rem;
  }

  .header-menu .nav .depth1 > li .depth2 {
    margin-top: 2rem;
    display: none;
  }

  .header-menu .nav .depth1 > li .depth2 > li {
    margin-bottom: 1rem;
  }

  .header-menu .nav .depth1 > li .depth2 > li > a {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) {
  .header-menu .nav .depth1 > li .depth2 {
    height: auto !important;
    display: flex !important;
  }
}

#footer {
  width: 100%;
}

.footer-inner {
  max-width: 1920px;
  margin: auto;
  padding: 4rem;
  position: relative;
}

.footer-inner .footer-left {
  background-color: #282e42;
  padding: 3rem;
  display: flex;
}

.footer-inner .footer-left .footer-logo {
  width: 40%;
  align-items: center;
  display: flex;
}

.footer-inner .footer-left .footer-logo > img {
  max-width: 100%;
  max-height: 120px;
}

.footer-inner .footer-left .footer-info {
  width: 60%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.footer-inner .footer-left .footer-info .footer-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer-inner .footer-left .footer-info .footer-link > li {
  margin-right: 1rem;
}

.footer-inner .footer-left .footer-info .footer-link > li > a, .footer-inner .footer-left .footer-info .footer-info-item .title {
  color: #fff;
  font-size: 1rem;
}

.footer-inner .footer-left .footer-info .footer-info-item .sub {
  color: #fff;
  font-size: .9rem;
}

.footer-inner .footer-left .footer-info .footer-copyright > span {
  color: #fff;
  font-size: .8rem;
}

.footer-inner .footer-right {
  padding: 2rem 0 2rem 2rem;
}

.footer-inner .footer-right .copyright > div:nth-of-type(1) {
  color: #2478d7;
  font-size: 1.8rem;
}

.footer-inner .footer-right .copyright > div:nth-of-type(2) {
  color: #fff;
  font-size: 2rem;
}

.footer-inner .footer-right .pdf-container {
  margin-top: 2rem;
}

.footer-inner .footer-right .pdf-container > button {
  background-color: #282f42;
  border: none;
  border-radius: 5px;
  padding: .8rem 1.2rem;
}

.footer-inner .footer-right .pdf-container > button > a {
  color: #fff;
  align-items: center;
  display: flex;
}

.footer-inner .footer-right .pdf-container > button > a > img {
  width: 20px;
  margin-left: 10px;
}

.footer-inner .footer-navigation {
  position: absolute;
  bottom: 50px;
  right: 100px;
}

.footer-inner .footer-navigation > button {
  color: #2478d7;
  transform-origin: 100%;
  cursor: pointer;
  background-color: #0000;
  border: none;
  align-items: center;
  gap: 1rem;
  font-size: .8rem;
  display: flex;
  transform: rotate(90deg);
}

.footer-inner .footer-navigation > button:before {
  content: "";
  width: 70px;
  height: 1px;
  background: #2478d7;
  display: block;
}

@media (max-width: 768px) {
  .footer-inner {
    padding: 0;
  }

  .footer-inner .footer-left .footer-logo {
    width: 30%;
    padding-right: 20px;
  }

  .footer-inner .footer-left .footer-logo > img {
    max-height: 90px;
    max-width: 100%;
  }

  .footer-inner .footer-left .footer-info {
    width: 70%;
  }

  .footer-inner .footer-left .footer-info .footer-link > li > a {
    font-size: 1.2rem;
  }

  .footer-inner .footer-left .footer-info .footer-info-item {
    margin: 1rem 0;
  }

  .footer-inner .footer-left .footer-info .footer-info-item .title {
    font-size: 1.1rem;
  }

  .footer-inner .footer-left .footer-info .footer-info-item .sub {
    font-size: 1rem;
  }

  .footer-inner .footer-navigation {
    bottom: 20px;
    right: 15px;
  }
}

.popup-list .popup-box {
  max-width: 450px;
  z-index: 1000;
  position: fixed;
  box-shadow: 0 15px 25px #0000004d, 0 5px 10px #0003;
}

.popup-list .popup-box[data-index="1"] {
  top: 50%;
  left: 50%;
  transform: translate(-120%, -60%);
}

.popup-list .popup-box[data-index="2"] {
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(16%, -48%);
}

.popup-list .popup-box[data-index="3"] {
  top: 50%;
  left: 50%;
  transform: translate(20%, -58.5%);
}

.popup-list .popup-box[data-index="3"] .link-btn {
  cursor: pointer;
  width: 26%;
  height: 5%;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 83.6%;
  left: 50%;
  transform: translate(-50%);
}

.popup-list .popup-box .content {
  position: relative;
}

.popup-list .popup-box .content img {
  width: 100%;
  height: auto;
}

.popup-list .popup-box .bottom-bar {
  background-color: #ffffff80;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.popup-list .popup-box .bottom-bar .close-btn > i {
  color: #fff;
  font-size: .8rem;
}

.popup-list .popup-box .bottom-bar .close-btn > span, .popup-list .popup-box .bottom-bar .checkbox {
  cursor: pointer;
  font-size: .9rem;
}

@media (max-width: 768px) {
  .popup-list .popup-box {
    max-width: 360px;
    width: 80vw;
    z-index: 1000;
    position: fixed;
    box-shadow: 0 15px 25px #0000004d, 0 5px 10px #0003;
  }

  .popup-list .popup-box[data-index="1"], .popup-list .popup-box[data-index="2"], .popup-list .popup-box[data-index="3"] {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .popup-list .popup-box .content img {
    width: 100%;
    height: auto;
  }

  .popup-list .popup-box .bottom-bar {
    background-color: #ffffff80;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    display: flex;
  }

  .popup-list .popup-box .bottom-bar .close-btn > i {
    color: #fff;
    font-size: 1.2rem;
  }

  .popup-list .popup-box .bottom-bar .close-btn > span, .popup-list .popup-box .bottom-bar .checkbox {
    cursor: pointer;
    font-size: 1.2rem;
  }
}

/*# sourceMappingURL=index.2a7c31a0.css.map */
